import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { FakeUsersService } from '@fca-app/services/fake-users.service';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';
import { fileToBase64 } from '@fca-app/shared/helpers/file-to-base64.helper';
import { UploadedCropperImage } from '@fca-app/shared/interfaces/uploaded-cropper-image.interface';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'arena-event-create-fake-user',
    styleUrls: ['./arena-event-create-fake-user.component.scss'],
    templateUrl: './arena-event-create-fake-user.component.html',
})
export class ArenaEventCreateFakeUserComponent implements AfterViewInit {
    imageType = EImageType;
    isModalVisible$ = new BehaviorSubject<boolean>(false);
    imageChangedEvent: Event;
    form: FormGroup;

    constructor(
        private modal: NzModalRef,
        private readonly appConfigService: AppMainConfigService,
        private readonly fakeUsersService: FakeUsersService,
        private readonly nzNotificationService: NzNotificationService
    ) {
        this.form = new FormGroup({
            firstName: new FormControl(null, [Validators.required]),
            lastName: new FormControl(null, [Validators.required]),
            image: new FormControl(null, []),
            file: new FormControl(null, []),
        });
        this.form.statusChanges.pipe(untilDestroy(this)).subscribe(() => {
            this.modal.updateConfig({ nzOkDisabled: !this.form.valid });
        });

        this.modal.updateConfig({
            nzOnOk: () => {
                if (this.form.valid) {
                    const { firstName, lastName } = this.form.value;
                    const image = this.form.value.image;

                    this.fakeUsersService
                        .create(firstName, lastName, image)
                        .pipe(untilDestroy(this), take(1))
                        .subscribe(model => {
                            this.nzNotificationService
                                .success(`"${lastName} ${firstName}" has been created`, '', { nzDuration: 1500 })
                                .onClick.pipe(take(1), untilDestroy(this))
                                .subscribe();
                        });
                }
            },
        });
    }

    async onPhotoUpload(uploadedImage: UploadedCropperImage) {
        this.isModalVisible$.next(false);
        this.form.patchValue({
            image: String(await fileToBase64(uploadedImage.file)),
            file: uploadedImage.file,
        });
        (this.imageChangedEvent.target as HTMLInputElement).value = '';
    }

    onCloseCropper() {
        this.isModalVisible$.next(false);
    }

    edit(e: Event) {
        this.imageChangedEvent = e;
        this.isModalVisible$.next(true);
    }

    get imagePreviewUrl(): string {
        return this.form.value.image || this.appConfigService.getConfig().previewImage;
    }

    ngAfterViewInit(): void {
        this.modal.updateConfig({ nzOkDisabled: true });
    }
}
